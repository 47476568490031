<template>
  <!-- <div>系统中心 - 安全设置</div> -->
  <div>
    <el-container class="container">
      <el-header class="header">安全设置</el-header>
      <el-main class="main">
        <el-form
          label-width="100px"
          class="form"
          label-position="left"
          :model="ruleform"
          :rules="rules"
          ref="tableForm"
        >
          <el-form-item label="旧密码" prop="oldPwd">
            <el-input
              v-model.trim="ruleform.oldPwd"
              show-password
              clearable
              name="oldPwd"
              placeholder="请输入旧密码"
              @blur="formChange"
              @keyup.native="
                ruleform.oldPwd = ruleform.oldPwd.replace(
                  /[^\a-\z\A-\Z0-9]/g,
                  ''
                )
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd">
            <el-input
              v-model="ruleform.newPwd"
              show-password
              clearable
              minlength="6"
              maxlength="20"
              name="newPwd"
              @blur="formChange"
              placeholder="请输入新密码"
            ></el-input>
              <!-- @keyup.native="
                ruleform.newPwd = ruleform.newPwd.replace(
                  /[^\a-\z\A-\Z0-9]/g,
                  ''
                )
              " -->
          </el-form-item>
          <el-form-item label="确认新密码" prop="confirmPwd">
            <el-input
              v-model="ruleform.confirmPwd"
              show-password
              clearable
              minlength="6"
              maxlength="20"
              name="confirmPwd"
              @blur="formChange"
              placeholder="请再次输入新密码"
            ></el-input>
              <!-- @keyup.native="
                ruleform.confirmPwd = ruleform.confirmPwd.replace(
                  /[^\a-\z\A-\Z0-9]/g,
                  ''
                )
              " -->
          </el-form-item>
          <el-form-item
            label="手机号码"
            prop="phoneNumber"
            style="position: relative"
          >
            <el-input
              v-model="ruleform.phoneNumber"
              minlength="3"
              maxlength="20"
              name="phoneNumber"
              clearable
              placeholder="请输入手机号"
              :disabled="phoneBlooean"
              @blur="formChange"
              @keyup.native="
                ruleform.phoneNumber = ruleform.phoneNumber.replace(
                  /[^0-9]/g,
                  ''
                )
              "
            ></el-input>
            <!-- 判断是否已经验证手机号 -->
            <span class="phoneVerification" v-show="phoneBlooean">
              <i class="el-icon-warning-outline" v-if="!phoneVeifBlooean"></i>
              <span v-text="phoneVeifBlooean ? ' 已验证' : ' 未验证'"></span>
            </span>
            <el-tag
              v-show="phoneBlooean"
              class="phoneNewBin"
              @click="phoneNewBinFun"
              effect="plain"
              >重新绑定</el-tag
            >
          </el-form-item>
          <el-form-item label="验证码" prop="verificationCode">
            <el-input
              v-model.trim="ruleform.verificationCode"
              clearable
              minlength="6"
              maxlength="6"
              placeholder="请输入验证码"
              name="verificationCode"
              @blur="formChange"
              @keyup.native="
                ruleform.verificationCode = ruleform.verificationCode.replace(
                  /[^\d]/g,
                  ''
                )
              "
              style="width: 150px; position: relative"
            ></el-input>
            <el-button
              type="primary"
              class="CodeButton"
              @click="myVerificationCode"
              :disabled="CodeBlooean"
              >{{ codeText }}</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 80px" @click="onSaveData"
              >确定</el-button
            >
            <el-button
              style="width: 80px; margin-left: 20px"
              @click="onclearData"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// 这里引入获取手机号，修改密码 、 发送验证码 的接口
import {
  getPhoneInfo,
  modifyNewPwd,
  sendVerifyCode,
} from "@/api/All-Interface.js";
import { encrypt, decrypt } from "@/utils/aes-ecb";
import { mapState, mapActions } from "vuex";
import { validatePassword } from "@/utils/auth";
export default {
  // `name`与路由保持一致
  name: "SecuritySettings",
  data() {
    const validateNewPwd = (rules, value, callback) => {
      if (this.ruleform.newPwd !== value) {
        callback(new Error("再次输入密码不一致!"));
      }
    };
    let formValidatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!validatePassword(value) && value) {
        callback(new Error("请输入密码最小长度为8位，密码由数字、字母和特殊字符组合"));
      } else {
        callback();
      }
    };
    return {
      oldPwd: "", //旧密码
      newPwd: "", //新密码
      confirmPwd: "", //确认新密码
      phoneNumber: "", //手机号
      verificationCode: "", //验证码
      phoneBlooean: false, //控制手机号input是否可以输入
      phoneVeifBlooean: false, //手机号认证状态，
      CodeBlooean: false, //验证码框是否是开启状态
      codeText: "获取验证码", //验证码文本
      ruleform: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: "",
        phoneNumber: "",
        verificationCode: "",
      },
      rules: {
        oldPwd: [
          {
            required: true,
            message: "请输入旧密码,6-20位数",
            trigger: "blur",
            min: 6,
            max: 20,
          },
        ],
        newPwd: [
          { required: true, validator: formValidatePassword, trigger: "change" },
        ],
        confirmPwd: [
          { required: true, validator: formValidatePassword, trigger: "change" },
        ],
        phoneNumber: [
          {
            required: true,
            message: "请输入正确的手机号码",
            trigger: "blur",
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          },
        ],
        verificationCode: [
          {
            required: true,
            message: "请输入正确的验证码",
            trigger: "blur",
            min: 6,
            max: 6,
          },
        ],
      },
    };
  },
  mounted() {
    //一进来先判断当前手机号信息，是否是已经验证
    this.getPhoneInfo();
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    //一进来先判断当前手机号信息，是否是已经验证
    getPhoneInfo() {
      getPhoneInfo().then((res) => {
        if (res.flag == 1) {
          this.ruleform.phoneNumber = res.obj.phone;
          this.phoneBlooean = true; //开启手机号不可编辑
          if (res.obj.phoneValid == 1) {
            //为 1 说明手机号已经验证
            this.phoneVeifBlooean = true;
          }
        }
      });
      //初始化页面
      //清空输入的密码和验证码
      this.ruleform.oldPwd = "";
      this.ruleform.newPwd = "";
      this.ruleform.confirmPwd = "";
      this.ruleform.verificationCode = "";
    },

    //点击确定，修改密码
    async onSaveData() {
        this.$refs['tableForm'].validate(async (valid) => {
          if (valid) {
            if (!this.valdateData()) return; //输入框判断无误，则进行下一步
            try {
              const res = await modifyNewPwd({
                bPwd: encrypt(this.ruleform.oldPwd),
                nPwd: encrypt(this.ruleform.newPwd),
                verifyCode: this.ruleform.verificationCode,
                phone: this.ruleform.phoneNumber,
              });
              // 密码修改失败时
              if (res.flag !== 1) return this.$message.warning(res.msg);
              this.$message.success(res.msg + "请重新登录");
              this.$router.push("/login"); // 跳转至登录开始页面
            } catch (error) {
              this.$message.warning("查询异常");
              console.log(error);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    },
    //针对输入时通过鼠标粘贴中文或特殊字符
    formChange(event) {
      // if (event.currentTarget.name == "oldPwd") {
      //   event.currentTarget.value = event.currentTarget.value.replace(
      //     /[^\a-\z\A-\Z0-9]/g,
      //     ""
      //   );
      //   this.ruleform.oldPwd = event.currentTarget.value;
      // } else if (event.currentTarget.name == "newPwd") {
      //   event.currentTarget.value = event.currentTarget.value.replace(
      //     /[^\a-\z\A-\Z0-9]/g,
      //     ""
      //   );
      //   this.ruleform.newPwd = event.currentTarget.value;
      // } else if (event.currentTarget.name == "confirmPwd") {
      //   event.currentTarget.value = event.currentTarget.value.replace(
      //     /[^\a-\z\A-\Z0-9]/g,
      //     ""
      //   );
      //   this.ruleform.confirmPwd = event.currentTarget.value;
      // } else 
      if (event.currentTarget.name == "phoneNumber") {
        event.currentTarget.value = event.currentTarget.value.replace(
          /[^0-9]/g,
          ""
        );
        this.ruleform.phoneNumber = event.currentTarget.value;
      } else if (event.currentTarget.name == "verificationCode") {
        event.currentTarget.value = event.currentTarget.value.replace(
          /[^\d]/g,
          ""
        );
        this.ruleform.verificationCode = event.currentTarget.value;
      }
    },
    //提交时，进行输入框的判断
    valdateData() {
      if (!(this.ruleform.newPwd && this.ruleform.newPwd.length >= 6)) {
        this.$message.warning("密码最少6位字符");
        return false;
      }
      if (this.ruleform.newPwd !== this.ruleform.confirmPwd) {
        this.$message.warning("两次输入的新密码不一致");
        return false;
      }
      if (this.ruleform.phoneNumber.length != 11) {
        this.$message.warning("请输入11位纯数字组成的手机号码");
        return false;
      }
      if (this.ruleform.verificationCode.length <= 3) {
        this.$message.warning("请输入6位验证码");
        return false;
      }
      return true;
    },
    //清除表单信息
    onclearData() {
      // 清除验证提示信息
      this.$refs["tableForm"].resetFields();
      this.getPhoneInfo();
    },
    //点击重新绑定手机号
    phoneNewBinFun() {
      this.ruleform.phoneNumber = "";
      this.phoneBlooean = false;
      this.phoneVeifBlooean = false;
    },
    //发送验证码
    myVerificationCode() {
      //获取input 里的手机号，如果没有就提醒
      if (this.ruleform.phoneNumber == "") {
        this.$message("请输入手机号后, 再点击发送验证码");
        return;
      } else if (this.ruleform.phoneNumber.length != 11) {
        this.$message("请输入11位纯数字组成的手机号码");
        return;
      } else {
        //这里发验证码
        sendVerifyCode({ phone: this.ruleform.phoneNumber }).then((res) => {
          if (res.flag != 1) {
            //flag 不为 1 则 说明验证码请求失败
            this.$message({
              message: res.msg + "，请 60 秒后再获取验证码",
              type: "warning",
            });
          }
        });
      }
      //60秒后再开启发送验证码框
      this.countDown(60);
    },
    countDown(time) {
      //这个是验证码倒计时
      if (time === 0) {
        this.CodeBlooean = false;
        this.codeText = "获取验证码";
        return;
      } else {
        this.CodeBlooean = true;
        this.codeText = "重新发送（" + time + "）";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/style/reportStyle.scss";
.el-form-item {
  margin-bottom: 30px;
  .el-input {
    width: 260px;
  }
}
.phoneVerification {
  //手机号验证状态
  display: inline-block;
  width: 60px;
  position: absolute;
  right: 200px;
  bottom: -25px;
  font-size: 10px;
  color: black;
}
.phoneNewBin {
  //重新绑定手机号
  position: absolute;
  right: -70px;
  bottom: 0px;
  cursor: pointer;
}

.container {
  width: 44%;
  height: 80vh;
  margin: 5vh 26%;
  .header {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid;
    line-height: 60px;
    color: #1890ff;
    font-size: 16px;
    font-weight: bold;
  }
  .main {
    display: flex;
    justify-content: center;
    .form {
      margin: 30px 0;
      width: 360px;
      .CodeButton {
        position: absolute;
        right: 0px;
        width: 100px;
        text-align: center;
      }
    }
  }
}
</style>